import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import StartProject from '../components/Common/StartProject'

const Vacancies = () => {
    return (
        <Layout>
            <Navbar />

            <PageBanner
                pageTitle="Careers" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Vacancies" 
            />

            <div className="ptb-100">
                <div className="container">
                    <div className="faq-accordion">
                        <Accordion allowZeroExpanded preExpanded={['a']}>
                            {/* <AccordionItem uuid="a">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        1 x Business Analyst 
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Responsible for bridging the gap between our technical experts and our clients. Part of your responsibilities would be to leverage digital tools and techniques such as data analytics to assess processs, determine requirements and deliver data-driven recommendations to our executives and stakeholders.
                                    </p>
                                    <p>
                                        Email info@theparse.co.za for more information or to apply.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem> */}
                            
                            {/* 
                            <AccordionItem uuid="b">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    1 x Digital Marketing Specialist
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Develop, implement and manage digital marketing campaigns. The Marketing Consultant partners with business and the Senior Marketing manager to formulate the marketing and brand strategy. The incumbent implements aspects of the marketing plan with the objective of influencing targeted consumers to choose theParse's skills and services as well as to achieve specific measurable business and marketing objectives. 
                                    </p>
                                    <p>
                                        Email info@theparse.co.za for more information or to apply.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem> */}

                      `      {/* <AccordionItem uuid="c">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                       Head of Digital Recruiting
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Leverage internally defined recruiting tools and platforms to source, assess, attract, select and recruit potential candidates for available positions.
                                    </p>
                                    <p>
                                        Email info@theparse.co.za to apply for this post.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>` */}

                            <AccordionItem uuid="d">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        1 x UI UX Expert - Freelance
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Work closely with the design, development and SEO teams to investigate and analyse requirements, analyse and diagram profiles and workflows, develop highly usable interface solutions.
                                    </p>
                                    <p>
                                        Email info@theparse.co.za to apply for this post.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            {/* <AccordionItem uuid="e">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        1 x Senior Data Scientist
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Develop Hyper Personalisation and Machine Learning solutions for thePares AI division. The Big Data Science Team in thePares AI division drives personalisation and data-driven decisions making. Using the data store and AI Engine to drive engagement and customer-centricity and product development using AI.
                                    </p>
                                    <p>
                                        Email info@theparse.co.za to apply for this post.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem> */}

                            {/* <AccordionItem uuid="f">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    
                                    </p>
                                    <p>
                                        Email careers@theparse.co.za to apply for this post.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem> */}
                        </Accordion>
                    </div>
                </div>
            </div>

            <StartProject />
            
            <Footer />
        </Layout>
    );
}

export default Vacancies